const Filters = {
    dairy:{
        group:"allergy",
        value:"dairy",
        name:"Dairy",
        checked:false
    },
    eggs:{
        group:"allergy",
        value:"eggs",
        name:"Eggs",
        checked:false
    },
    fish:{
        group:"allergy",
        value:"fish",
        name:"Fish",
        checked:false
    },
    gluten:{
        group:"allergy",
        value:"gluten",
        name:"Gluten",
        checked:false
    },
    peanuts:{
        group:"allergy",
        value:"peanuts",
        name:"Peanuts",
        checked:false
    },
    shellfish:{
        group:"allergy",
        value:"shellfish",
        name:"Shellfish",
        checked:false
    },
    soya:{
        group:"allergy",
        value:"soya",
        name:"Soya",
        checked:false
    },
    treeNuts:{
        group:"allergy",
        value:"treeNuts",
        name:"Tree Nuts",
        checked:false
    },
    wheat:{
        group:"allergy",
        value:"wheat",
        name:"Wheat",
        checked:false
    },
    freealc:{
        group:"diet",
        value:"freealc",
        name:"Free Alcohol",
        checked:false
    },
    balanced:{
        group:"diet",
        value:"balanced",
        name:"Balanced",
        checked:false
    },
    highFiber:{
        group:"diet",
        value:"highFiber",
        name:"High Fibers",
        checked:false
    },
    lowCarb:{
        group:"diet",
        value:"lowCarb",
        name:"Low Carb",
        checked:false
    },
    lowFat:{
        group:"diet",
        value:"lowFat",
        name:"Low Fat",
        checked:false
    },
    lowSodium:{
        group:"diet",
        value:"lowSodium",
        name:"Low Sodium",
        checked:false
    },
    lowSugar:{
        group:"diet",
        value:"lowSugar",
        name:"Low Sugar",
        checked:false
    },
    paleo:{
        group:"diet",
        value:"paleo",
        name:"Paleo",
        checked:false
    },
    vegan:{
        group:"diet",
        value:"vegan",
        name:"Vegan",
        checked:false
    },
    vegetarian:{
        group:"diet",
        value:"vegetarian",
        name:"Vegetarian",
        checked:false
    }
};

export default Filters;