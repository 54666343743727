import React, {Component} from "react";
import PropTypes from 'prop-types';
import RecipeItem from './RecipeItem';
import '../App.css';

class RecipeList extends Component {
    render() {

        const recipesToDisplay = this.props.recipeIdeas.map(i =>
            <RecipeItem
                name={i.RecipeName}
                author={i.RecipeAuthor}
                img={i.Recipeimg}
                url={i.RecipeUrl}
                cal={i.RecipeCal}
                ingredients={i.RecipeIngredients}
                key={i.RecipeId}
            />
        );

        return (
            <ul id='recipeList'>
                {recipesToDisplay}
            </ul>
        );

    }
}

RecipeList.propTypes = {
    recipeIdeas: PropTypes.array.isRequired
};

export default RecipeList;
