import React, {Component} from "react";
import '../App.css';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Footer extends Component {
    render() {

        

        return (
            <div className="footerWrapper" >
                <p><FontAwesomeIcon icon={faStar} />Made by <a href='https://www.sodev.me' target='_blank'>sodev.me</a>, using the following APIs: <a href='https://developers.zomato.com/api' target='_blank'>Zomato</a>, <a href='https://developer.edamam.com/' target='_blank'>Edamam</a> and <a href='https://firebase.google.com/' target='_blank'>Firebase</a></p>
            </div>
        );

    }
}

export default Footer;
