import React, {Component} from "react";
import PropTypes from 'prop-types';
import ItemsToDisplay from './ItemToDisplay';
import '../App.css';

class Day extends Component {
    render() {

        const itemsToDisplay = this.props.meals.filter(i =>
            i.when === this.props.day || i.when === 'week'
        ).map(i =>
            <ItemsToDisplay
                meal={i.meal}
                who={i.who}
                key={i.id}
                onDelete={() => this.props.onDelete(i.id)}
            />
        );

        return (
            <ul>
                {itemsToDisplay}
            </ul>
        );

    }
}

Day.propTypes = {
    meals: PropTypes.array.isRequired,
    day: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default Day;
