import React, {Component} from "react";
import CheckboxItem from './CheckboxItem';
import '../App.css';

class CheckboxList extends Component {
    
    render() {

        const checkboxAllergies = Object.values(this.props.recipeFilter).filter(i =>
            i.group === 'allergy'
        ).map(i =>
            <CheckboxItem
                name="allergy"
                value={i.value}
                key={i.value}
                checked={i.checked}
                onChange={()=>this.props.handleCheck(i.id)}
            />
        );

        const checkboxDiet = Object.values(this.props.recipeFilter).filter(i =>
            i.group === 'diet'
        ).map(i =>
            <CheckboxItem
                name="diet"
                value={i.value}
                key={i.value}
                checked={i.checked}
                onChange={()=>this.props.handleCheck(i.id)}
            />
        );

        return (
            <div ref={i => this.filters = i} id="filters">
                <div ref={i => this.allergies = i}>
                    {checkboxAllergies}
                </div>
                <div ref={i => this.diet = i}>
                    {checkboxDiet}
                </div>
            </div>
        );

    }
}

export default CheckboxList;