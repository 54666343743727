import React, {Component} from 'react';
import classNames from 'classnames';
import * as firebase from 'firebase';
import Day from './Items/Day';
import IdeaList from './Items/IdeaList';
import RecipeList from './Items/RecipeList';
import CheckboxList from './Items/CheckboxList';
import Filters from './Items/Filters';
import Footer from './Items/Footer';
import Logo from './img/logo.png';
import './App.css';
import './mediaQueries.css';

// import your icons
import { faMitten, faPlusCircle, faRocket, faStar, faTimesCircle, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const week = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            meals: [],
            mealIdeas: [],
            recipeFilter: Filters,
            recipeIdeas: [],
            position:{
                longitude: 0,
                latitude: 0
            }
        };

        //Firebase for the databse of meals on the board
        const config = {
            apiKey: "AIzaSyARKoV7kCKRoSmjhtfNrszoWJH6I5_NZ0Y",
            authDomain: "meal-planner-191516.firebaseapp.com",
            databaseURL: "https://meal-planner-191516.firebaseio.com",
            projectId: "meal-planner-191516",
            storageBucket: "meal-planner-191516.appspot.com",
            messagingSenderId: "133917709873"
        };
        firebase.initializeApp(config);

        const database = firebase.database();

        this.mealsDatabaseRef = database.ref('meals');

        this.mealsDatabaseRef.on('value', snapshot => {
            const meals = [];

            const data = snapshot.val();
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    meals.push(Object.assign({id: key}, data[key]));
                }
            }

            this.setState({meals});
        });
    }

    //We use Zomato API in order to get the restaurants near your position
    callZomatoAPI(sortBy,order) {
        let currentPosition = {};
        this.getPosition().then(
            (position) =>{
                currentPosition = position;
                this.setState({position: {latitude: position.latitude, longitude: position.longitude}});
                return fetch(`https://developers.zomato.com/api/v2.1/search?lat=${position.latitude}&lon=${position.longitude}&radius=1,000&count=30&sort=${sortBy}&order=${order}`, {
                headers: new Headers({
                    'user-key': '31c4dcb8472adaa8b0ec4aae63d39ca0'
                }),
                mode: 'cors',
            })
        }
        )
        .then(response => response.json())
        .then(data => this.showIdeas(data,currentPosition));
    }

    //When you click the bulb it will show you the restaurants near you, with the Zomato API
    showIdeas(data,currentPosition) {
        console.log(data);
        let restaurant = data.restaurants;
        let ideasList = [];
        let destinations = '';
        for (let i = 0; i < data.restaurants.length; i++) {
            let idea = {
                id: restaurant[i].restaurant.id,
                name: restaurant[i].restaurant.name,
                cuisine: restaurant[i].restaurant.cuisines,
                address: restaurant[i].restaurant.location.address,
                longitude: restaurant[i].restaurant.location.longitude,
                latitude: restaurant[i].restaurant.location.latitude,
                distanceValue:'',
                price: restaurant[i].restaurant.price_range,
                rating: Number(restaurant[i].restaurant.user_rating.aggregate_rating),
                ratingColor: restaurant[i].restaurant.user_rating.rating_color,
                url: restaurant[i].restaurant.url,
            };
            ideasList.push(idea);

            destinations += `&point=${idea.latitude},${idea.longitude}`
        }

        //show the list of restaurants
        this.showPopUp(this.popUpMeal, 10);

        this.setState({mealIdeas: ideasList});        
    }

    getPosition(){
        if (!navigator.geolocation) {
            return Promise.reject();
        } else {
            return new Promise(function (resolve, reject) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        console.log(position);
                        resolve({
                            latitude: position.coords.latitude, 
                            longitude: position.coords.longitude
                        });
                    }, 
                    () => {
                        reject();
                    }
                );
            });
        }
        
    }
    
    //We can sort the ideas in the table
    sortIdeas(filter){
        let newTab = this.state.mealIdeas.slice();
        if(filter==='cost'){
            newTab.sort(function(a, b) {return (a.price - (b.price))});	
        }else{
            newTab.sort(function(a, b) {return (Number(b.rating) - (Number(a.rating)))});	
        }
        this.setState({mealIdeas:newTab});
    }

    //Global function to show a popup
    showPopUp(popup) {
        this.modal.classList.toggle('mOpen');
        popup.style.opacity = '1';
        popup.style.display = 'flex';
    }

    //Global function to close a popup
    closePopUp(popup) {
        this.modal.classList.toggle('mOpen');
        popup.style.opacity = '0';
        popup.style.display = 'none';

        if (popup === this.popUpAdd) {
            this.popUpAddWhen.value = '';
            this.popUpAddWho.value = '';
            this.popUpAddWhat.value = '';
        }
        if (popup === this.popUpRecipes) {
            this.searchRecipe.value = '';
            this.setState({recipeIdeas:[]});
        }
    }

    //When clicking on the + icon, we can add a meal on the board with the API Firebase
    addMeal() {
        let newWho = this.popUpAddWho.value;
        let newMeal = this.popUpAddWhat.value;
        let newDay = this.popUpAddWhen.value;

        if (newDay === 'week') {

            for (let i = 0; i < week.length; i++) {
                let newTicket = {meal: newMeal, who: newWho, when: week[i]};
                this.mealsDatabaseRef.push().set(newTicket);
            }

        } else {
            let newTicket = {meal: newMeal, who: newWho, when: newDay};
            this.mealsDatabaseRef.push().set(newTicket);
        }
        this.popUpAddWhen.value = '';
        this.popUpAddWho.value = '';
        this.popUpAddWhat.value = '';

        this.closePopUp(this.popUpAdd);
    }
    
  //We can have recipes idea clicking the book button, We are using the API Edamam  
  callEdamamAPI(){
        let newRecipe = this.searchRecipe.value;  
        this.searchRecipe.value = '';      
        let parameters = '&q='+ newRecipe;
        let urlMaps = 'https://api.edamam.com/search?app_id=af40af40&app_key=        3bdd00b8311ab50ed025502932129c6b' + parameters;

       fetch(urlMaps, {
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
            })
        })
        .then(response => response.json())
        .then(data => this.showRecipes(data));
    }
    
    //We show the table of the different recipes found
    showRecipes(data){
        let apiData = data.hits;
        let newRecipeIdeas =[];
        console.log(data);
        
        for (let i = 0; i < data.hits.length; i++) {
            
            let ingredientList = '';
            for (let j = 0; j < data.hits[i].recipe.ingredientLines.length; j++) {
                let newingre = data.hits[i].recipe.ingredientLines[j];
                if(j===data.hits[i].recipe.ingredientLines.length-1){
                    ingredientList+= newingre;
                }else{
                    ingredientList+= newingre + ', ';
                }
            }
            
            
            let newRecipe = {
                RecipeName : apiData[i].recipe.label,
                RecipeAuthor : apiData[i].recipe.source,
                Recipeimg : apiData[i].recipe.image,
                RecipeUrl : apiData[i].recipe.shareAs,
                RecipeCal : apiData[i].recipe.calories,
                RecipeIngredients : ingredientList,
                RecipeId : i
            }
            newRecipeIdeas.push(newRecipe);
        }
        console.log(newRecipeIdeas);
        this.setState({recipeIdeas:newRecipeIdeas});
    }

    //Function to delete one item on the board
    onDelete(key) {
        this.mealsDatabaseRef.child(key).remove();
    }
    
    handleCheck(key) {
        
        this.setState({checked: !this.state.checked});
    }


    render() {
        const days = week.map(day =>
            <li className={classNames("day", day)} key={day}>
                <div className="dayTitle">{day.charAt(0).toUpperCase() + day.slice(1)}</div>
                <Day
                    meals={this.state.meals}
                    onDelete={(key) => this.onDelete(key)}
                    day={day}
                />
            </li>
        );

        const options = week.map(day =>
            <option value={day} key={day}>{day.charAt(0).toUpperCase() + day.slice(1)}</option>
        );


        return (
            <div className="App">
                <div className="App-header">
                    <div className="App-header-title">
                        <div className="App-header-title-logo">
                            <img src={Logo} alt="Meal planner logo" className="logoHeader"/>
                        </div>
                        <h1>Meal Planner</h1>
                    </div>
                    <div className='separator'></div>
                </div>
                <div className="btnWrapper">
                    <div className="idea btn" onClick={() => this.callZomatoAPI('real_distance','desc')}>
                        <FontAwesomeIcon icon={faLightbulb} />
                    </div>
                    <div className="recipes btn" onClick={() => this.showPopUp(this.popUpRecipes, 10)}>
                        <FontAwesomeIcon icon={faMitten} />
                    </div>
                    <div className="add btn" onClick={() => this.showPopUp(this.popUpAdd, 37)}>
                        <FontAwesomeIcon icon={faPlusCircle} />  
                    </div>
                </div> 
                <ul className="table">
                    {days}
                </ul>
                
                <div className="modal" ref={i => this.modal = i}>
                    <div id="popUpMeal" ref={i => this.popUpMeal = i} className="popUp">
                        <div className="header">
                            <div className="closeBtn" onClick={() => this.closePopUp(this.popUpMeal)}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </div>
                            <h2>Want some meal ideas?</h2>
                        </div>                        
                        <div className='sortIdeasBloc'>
                            <p className='instruction'><b>Sort the results by: </b></p>
                            
                            <input type="radio" name="sortIdeas" id="sortRatings" value="ratings" onClick={() => this.sortIdeas('ratings')}/>
                            <label htmlFor="sortRatings">
                                <FontAwesomeIcon icon={faStar} />  
                                Rating
                            </label> 
                            
                            <input type="radio" name="sortIdeas" id="sortCost" value="cost" onClick={() => this.sortIdeas('cost')}/>
                            <label htmlFor="sortCost">£ Cost</label>
                        </div>    
                        <IdeaList
                            mealIdeas={this.state.mealIdeas}
                            currentLongitude={this.state.position.longitude}
                            currentLatitude={this.state.position.latitude}
                        />
                    </div>
                        
                    <div id="popUpRecipes" ref={i => this.popUpRecipes = i} className="popUp">
                        <div className="header">
                            <div className="closeBtn" onClick={() => this.closePopUp(this.popUpRecipes)}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </div>
                            <h2>Want some recipe ideas?</h2>
                        </div> 
                        <div className="popForm">
                            <form>
                                <div ref={i => this.inputRecipes = i} id="inputRecipes">
                                    <input type="text" placeholder="Ingredient/Recipe *" ref={i => this.searchRecipe = i} id="searchRecipe"/>
                                </div>
                                <CheckboxList 
                                    recipeFilter={this.state.recipeFilter}
                                />
                            </form>
                            <div className="submit" onClick={() => this.callEdamamAPI()} data-txt="Search"></div>
                        </div>
                        <RecipeList
                            recipeIdeas={this.state.recipeIdeas}
                            ref={i => this.recipeList = i}
                        />
                    </div>    
                        
                    
                    <div id="popUpAdd" ref={i => this.popUpAdd = i} className="popUp">
                        <div className="header">
                            <div className="closeBtn" onClick={() => this.closePopUp(this.popUpAdd)}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </div>
                            <h2>Want to add a meal?</h2>
                        </div> 
                        <div className="popForm">
                            <form>
                                <input type="text" placeholder="Who" ref={i => this.popUpAddWho = i} id="who"/>
                                <input type="text" placeholder="What food" ref={i => this.popUpAddWhat = i} id="what"/>
                                <select ref={i => this.popUpAddWhen = i} id="when" defaultValue="default">
                                    <option value='default' disabled>For when</option>
                                    {options}
                                    <option value="week">All the week</option>
                                </select>
                            </form>
                            <div className="buttonsPop">
                                <div className="add2" onClick={() => this.addMeal()}>+</div>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="App-footer">
                    < Footer />
                </div>
            </div>
        );
    }
}

export default App;
