import React, {Component} from "react";
import PropTypes from 'prop-types';
import IdeaItem from './IdeaItem';
import '../App.css';

class IdeaList extends Component {
    render() {

        const ideaItem = this.props.mealIdeas.map(i =>
            <IdeaItem
                name={i.name}
                cuisine={i.cuisine}
                address={i.address}
                price={i.price}
                url={i.url}
                rating={i.rating}
                ratingColor={i.ratingColor}
                key={i.id}
                longitude={i.longitude}
                latitude={i.latitude}
                currentLatitude={this.props.currentLatitude}
                currentLongitude={this.props.currentLongitude}
            />
        );

        return (
            <ul id='mealIdeasTable'>
                {ideaItem}
            </ul>
        );

    }
}

IdeaList.propTypes = {
    mealIdeas: PropTypes.array.isRequired,
};

export default IdeaList;
