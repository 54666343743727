import React, {Component} from "react";
import '../App.css';

class CheckboxItem extends Component {    
    render() {

        return (
            <div className="checkboxBloc">
                <input 
                    type="checkbox"
                    name={this.props.name} 
                    value={this.props.value}
                    checked={this.props.checked}
                    onChange={()=>this.props.handleCheck()}
                />
                <label htmlFor={this.props.value}>{this.props.value}</label>
            </div>
               
        );

    }
}

export default CheckboxItem;