import React, {Component} from 'react';
import PropTypes from 'prop-types';
import '../App.css';
// import your icons
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ItemsToDisplay extends Component {
    render() {

        return (
            <li className="mealBlock">
                <div className="mealDetails">
                    <h3 className="mealWho">{this.props.who}</h3>
                    <p className="mealWhat">{this.props.meal}</p>
                </div>
                <FontAwesomeIcon icon={faTrash} aria-hidden="true" onClick={() => this.props.onDelete()} />
            </li>
        );

    }
}

ItemsToDisplay.propTypes = {
    who: PropTypes.string.isRequired,
    meal: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default ItemsToDisplay;
