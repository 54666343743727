import React, {Component} from 'react';
import PropTypes from 'prop-types';
import '../App.css';
// import your icons
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class RecipeItem extends Component {
    render() {

        return (
            <li className="recipeBlock">
                <img className="recipeImg" src={this.props.img} />
                <div className="recipeDetails">
                    <h4>{this.props.name}</h4>
                    <p>From {this.props.author}</p>
                    <p>{this.props.ingredients}</p>
                    <p>{Math.round(this.props.cal)}kcal</p>
                    <p>See recipe 
                        <a href={this.props.url} target="_blank">
                            <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
                        </a>
                    </p>
                </div>
            </li>
        );

    }
}

RecipeItem.propTypes = {
    name: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    cal: PropTypes.number.isRequired,
    ingredients: PropTypes.string.isRequired
};

export default RecipeItem;
