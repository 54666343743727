import React, {Component} from 'react';
import PropTypes from 'prop-types';
import '../App.css';
// import your icons
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faInfo, faRocket, faMapMarkerAlt, faStarHalfAlt, faStar as plainFaStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class IdeaItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        //add the color for the rating
        let style = {};
        if(this.props.rating==='0'){
            style.color = '#ff206e';
        }else{
            style.color = '#'+this.props.ratingColor;
        }

        // let ratingId = 'R'+this.props.id;

        //append different stars depending on the rating
        let starRating;
        if (this.props.rating >= 0 && this.props.rating < 1) {
            starRating = faStar;
        } else if (this.props.rating >= 1 && this.props.rating < 3) {
            starRating = faStarHalfAlt;
        } else {
            starRating = plainFaStar;
        }

        //append different number of £ depending on the price
        let pounds = '';
        if (this.props.price >= 0 && this.props.price < 1) {
            pounds = '£';
        } else if (this.props.price >= 1 && this.props.price < 2) {
            pounds = '££';
        } else {
            pounds = '£££';
        }
        
        let map = `https://www.google.com/maps/dir/?api=1&origin=${this.props.currentLatitude},${this.props.currentLongitude}&destination=${this.props.latitude},${this.props.longitude}`;

        return (
            <li className="ideaMeal">
                <h4>{this.props.name}</h4>
                <div className='bloc'>
                    <div className='restoBloc'>
                        <p>{this.props.cuisine}</p>
                    </div>
                    <div className='infoBloc'>
                        <p>{pounds}</p>
                        <div className='rating' style={style}>
                            <FontAwesomeIcon icon={starRating} />
                            <p>{this.props.rating}</p>
                        </div>
                    </div>
                    <div className='map'>
                        <a href={map} target='_blank'>
                            <FontAwesomeIcon icon={faMapMarkerAlt} className='fa-2x' />
                        </a>
                        <a href={this.props.url} target='_blank'>
                            <FontAwesomeIcon icon={faInfo} className='fa-2x' />
                        </a>
                    </div>
                </div>
            </li>
        );

    }
}

IdeaItem.propTypes = {
    rating: PropTypes.number.isRequired,
    ratingColor: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    cuisine: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    latitude:PropTypes.string.isRequired,
    longitude:PropTypes.string.isRequired
};

export default IdeaItem;
